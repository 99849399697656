export const MINIMUM_DATE = '1/1/1900';
export const PBRACKETS_UPLOADS_ROOT = 'https://pickleballbrackets.com/uploads';
export const proBracketLevelIDs = [2, 3, 4, 7];
export const ContactReasonMap = {
	VOLUNTEER: 'VOLUNTEER_CONTACT_FORM_REASON',
	REFEREES: 'REFEREE_CONTACT_FORM_REASON',
	SPONSORS: 'SPONSOR_CONTACT_FORM_REASON',
	VENDORS: 'VENDOR_CONTACT_FORM_REASON'
};
export const USER_IMPRESSION_KEY = 'user-impression-key';
